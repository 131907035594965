import React, { useState } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { map, uniq } from "lodash"
import Layout from "../components/layout"
import Seo from "../components/seo"

import BlogCard from "../components/blog-card"

const Blog = ({ data, location }) => {
  const [blogs, setBlogs] = useState(data.allBlogs.nodes)
  const [name, setName] = useState("");

  const tags = uniq(map(blogs, "tags_TextField").concat().join().split(","))

  const filterByTag = tag => {
    const filteredBlogs = blogs.filter(b => b.tags_TextField.includes(tag))

    setBlogs(filteredBlogs)
  }

  const setDefaultTags = () => {
    setBlogs(data.allBlogs.nodes)
  }

  const filterByName = () => {
    if (name) {
      const filteredBlogs = data.allBlogs.nodes.filter(b => {
        return b.title.toLowerCase().includes(name.toLowerCase())
      })

      setBlogs(filteredBlogs)
    } else {
      setBlogs(data.allBlogs.nodes)
    }
  }

  return (
    <Layout>
      <Seo title="Blog" location={location} />
      <div className="cha-blog">
        <div className="cha-blog__header" style={
          {
            backgroundImage: `url(${data.blogHeader.blogHeaderImage.url})`
          }}>
          <h1 className="cha-blog__header__title h1-header">{data.blogHeader.blogTitle}</h1>
          <div className="cha-blog__header__description">
            <p className="subhead">{data.blogHeader.blogSubTitle}</p>
          </div>
        </div>
        <div className="cha-blog__container">
          <div className="cha-blog__container__right mobile">
            <div className="cha-blog__container__right__input__group">
              <input placeholder="Blog search" value={name} onChange={(event) => setName(event.target.value)} />
              <div className="cha-blog__container__right__input__group__box">
                <StaticImage
                  className="cha-blog__container__right__input__group__search-icon"
                  src={"../images/search-icon.png"}
                  alt="search"
                  onClick={filterByName}
                />
              </div>
            </div>

            <div className="cha-blog__container__right__cta">
              <div className="cha-blog__container__right__cta__text">
                {data.sidebarBlogContent.text}
              </div>
              <a
                className="cha-blog-card__container__button"
                href={data.sidebarBlogContent.link.href}
              >
                {data.sidebarBlogContent.link.text}
              </a>
            </div>

            <div className="cha-blog__container__right__category">
              {tags.map(t => (
                <div
                  key={t}
                  className="cha-blog__container__right__category__label"
                  onClick={() => filterByTag(t)}
                  role="button"
                  aria-hidden="true"
                >
                  {t}
                </div>
              ))}

              <div
                className="cha-blog__container__right__category__filter"
                onClick={setDefaultTags}
                role="button"
                aria-hidden="true"
              >
                Reset Filter
              </div>
            </div>
          </div>
          <div className="containers">
            <div className="cha-blog__container__left">
              {blogs.map(blog => {
                return (
                  <BlogCard
                    key={blog.id}
                    image={blog.image ? blog.image.url : ''}
                    title={blog.title}
                    details={blog.details}
                    path={blog.path}
                  />
                )
              })}
            </div>
            <div className="cha-blog__container__right">
              <div className="cha-blog__container__right__input__group">
                <input placeholder="Blog search" value={name} onChange={(event) => setName(event.target.value)} />
                <div className="cha-blog__container__right__input__group__box">
                  <StaticImage
                    className="cha-blog__container__right__input__group__search-icon"
                    src={"../images/search-icon.png"}
                    alt="search"
                    onClick={filterByName}
                  />
                </div>
              </div>

              <div className="cha-blog__container__right__cta">
                <p className="cha-blog__container__right__cta__text body1">
                  {data.sidebarBlogContent.text}
                </p>
                <a
                  className="cha-blog-card__container__button"
                  href={data.sidebarBlogContent.link.href}
                >
                  {data.sidebarBlogContent.link.text}
                </a>
              </div>

              <div className="cha-blog__container__right__category">
                {tags.map(t => (
                  <div
                    key={t}
                    className="cha-blog__container__right__category__label"
                    onClick={() => filterByTag(t)}
                    role="button"
                    aria-hidden="true"
                  >
                    {t}
                  </div>
                ))}

                <div
                  className="cha-blog__container__right__category__filter button2"
                  onClick={setDefaultTags}
                  role="button"
                  aria-hidden="true"
                >
                  Reset Filter
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cha-blog__container__footer">
          <h4 className="cha-blog__container__footer__title h4-header">
            {data.blogSignupContent.headline}
          </h4>
          <p className="cha-blog__container__footer__description body1">
            {data.blogSignupContent.subHeadline}
          </p>
          <input placeholder="EMAIL" />
          <button className="cha-blog__container__footer__button button2" type="button">
            SUBMIT
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query MyQuery {
    allBlogs(sort: {order: DESC, fields: parsedPublishedDate}) {
      nodes {
        details
        id
        image {
          url
        }
        path
        title
        publishingDate
        tags_TextField
      }
    }
    blogHeader {
      blogTitle
      blogSubTitle
      blogHeaderImage {
        url
      }
    }
    blogSignupContent {
      headline
      subHeadline
    }
    sidebarBlogContent {
      text
      link {
        href
        text
      }
    }
  }
`
